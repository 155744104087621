<template>

<b-container>
  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
       <b-row>
    <b-col>
      <b-form>
        <b-form-group id="exampleInputGroup1"
                    label="Enter data for new poll"
                    label-for="inputTitle1"
                    description="">
           <b-form-input id="inputTitle1"
                      type="text"
                      v-model="form.title"
                      required
                      placeholder="Beispiel: 2. Mannschaft Hinserie 2018 (optional)">
        </b-form-input>
      </b-form-group>
      </b-form>
      <b-form inline>
              <label for="inputRound">Vor- oder Rückrunde:</label>
                <b-form-select left="@" class="mb-2 mr-sm-2 mb-sm-0" id="inputRound"
                              :options="rounds"
                              required
                              v-model="form.round">
                </b-form-select>
               
             
      </b-form>
      <b-form inline>
              <label for="inputGroupId">Group ID</label>
                <b-form-input id="inputGroupId"
                      type="text"
                      v-model="form.groupId"
                      required
                      placeholder="334193">
                </b-form-input>
                <label for="inputClubId">Club ID</label>
                <b-form-input id="inputClubId"
                      type="text"
                      v-model="form.clubId"
                      required
                      placeholder="172024 (optional)">
                </b-form-input>
                <label for="inputTeamNr">Team Nr</label>
                <b-form-select left="@" class="mb-2 mr-sm-2 mb-sm-0" id="inputTeamNr"
                              :options="teamNrs"
                              required
                              v-model="form.teamNr">
                </b-form-select>
             
      </b-form>
        </b-col>
      </b-row>
  <b-row>
    <b-col>
      <b-form-file v-model="file" accept=".csv" ref="fileinput" @change="processFileEvent($event)"></b-form-file>
      <b-button @click="clearFiles">Reset</b-button>
      <!-- <b-button @click="processFileTestData">TEST</b-button> -->
      <b-button :disabled="insertDb_Poll_Disabled" @click="insertDb_PollAndMatches()">INSERT POLL DB</b-button>
      <b-button :disabled="insertDb_Clubs_Disabled" @click="insertDb_Clubs()">INSERT CLUBs DB</b-button>
       <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>
    </b-col>
  </b-row>
    <b-col>
            <h5 style="background:lightgrey;">Input als Text</h5>
            <div>
          <pre><small>
            {{ inputText ? inputText : "-" }}
          </small></pre>
          <!-- <b-form-textarea id="textarea1"
                    plaintext
                    v-model="inputText"
                    placeholder=""
                    :rows="10"
                    >
            </b-form-textarea> -->
            </div>
    </b-col>
  <b-row>
    <b-col>
            <h5 style="background:lightgrey;">Parser-Output *Matches*</h5>
            <b-form-textarea id="textarea2"
                     v-model="parserResultMatches"
                     size="sm"
                     placeholder=""
                     :rows="10"
                     >
            </b-form-textarea>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
            <h5 style="background:lightgrey;">Parser-Output *Teams*</h5>
            <b-form-textarea id="textarea3"
                     v-model="parserResultClubs"
                     size="sm"
                     placeholder=""
                     :rows="10"
                     >
            </b-form-textarea>
    </b-col>
  </b-row>

  
  </div>
</b-container>
  
</template>

<script>
import DBAPI from '../db/dbapi'
import CsvMatchParser from '../helper/CsvMatchParser'
import { s2i, makeCopy } from '../helper/Utils'
import MessageAlertMixin from '../mixins/MessageAlertMixin'

export default {
  name: 'ImportMatches',
  mixins:[MessageAlertMixin],
  mounted() {
    this.processQuery()
    this.getData()
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processQuery()
      },
      'form': {
        handler(newForm, oldForm) {
          this.resetErrorMessage()
          this.clearFiles()
        },
        deep: true
      }
  },
  data () {
    return {
      appState:'loading',
      file: null,
      inputText:"",
      parserResultMatches:null,
      parserResultClubs:null,
      errorMessage:null,
      insertDb_Poll_Disabled: false,
      insertDb_Clubs_Disabled: false,
      form: {
        title: '',
        round: (new Date().getMonth()>3 && new Date().getMonth()<10) ? 'vr' : 'rr', // Vor- bzw. Rückrunde
        groupId: '356795',//null,
        clubId: null,
        teamNr: 1,
      },
      rounds: [
        // { text: 'Select One', value: null },
        { text: 'Vorrunde', value: 'vr' },
        { text: 'Rückrunde', value: 'rr' },
      ],
      teamNrs: [1,2,3,4,5,6,7,8,9,10,11,12,13
      ],
    }
  },
  computed: {
    
  },
  methods: {
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
      }
    
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      
      Promise.resolve({data:{}}).then(function(response) {
          // console.log("response.data", response.data.playerSchedules);
          if (response.data.error){
            self.appState = 'error'

            //app.errorMessage = response.data.message;
            // console.error(response.data.message)
					}	else {
            
            self.appState = 'ready'
          }       
      })
      .catch( function(error) {
          console.error("error in getData() (catch)", error)
          self.appState = 'error'
        });
      
    },
    goBack: function() {
      this.$router.go(-1);
    },
    clearFiles() {
      this.$refs.fileinput.reset();
    },
    processFileTestData() {
      let self = this
      let content = CsvMatchParser.getTestData()
      self.setInputText(content)
      return 
      ////////
    },
    processFileEvent(event) {
      this.processFile(event.target.files[0])
    },
    processFile(file) {
      let self = this
      this.resetErrorMessage()
      
      if (!this.form.groupId) {
        this.setErrorMessage("Group Id nicht gesetzt!")
        return
      }
      let f = file
      // console.log("file", file)
      if (f) {
        var r = new FileReader();
        r.onload = function(e) { 
          var content = e.target.result;
          // console.log( "Got the file\n" 
          //       +"name: " + f.name + "\n"
          //       +"type: " + f.type + "\n"
          //       +"size: " + f.size + " bytes\n"
          //       + "starts with: " + content)
          self.setInputText(content)            
        }
        r.readAsText(f, 'latin1');
      } else { 
        console.error("Failed to load file " + f.name);
        self.setErrorMessage("Failed to load file " + f.name)
      }

    },
    resetErrorMessage() {
      this.hideAlert()
    },
    setErrorMessage(msg) {
      this.showAlert(msg)
    },
    setInputText(content) {
      this.inputText = content
      this.onInputTextChanged()
    },
    onInputTextChanged() {
      if (!this.inputText) {
        this.parserResultMatches = null
        this.parserResultClubs = null
        return
      }
      let parser = new CsvMatchParser(this.inputText)
      var parserResult;
      try {
        parserResult = parser.makeMatchArrayForDb(
          this.form.clubId,
          this.form.teamNr,
          this.form.round)
      } catch(e) {
        this.setErrorMessage("error (parser): " + e)
        this.parserResultMatches = null
        this.parserResultClubs = null
        return
      }
      
      if (parserResult.matches.length === 0) {
        this.setErrorMessage("parse error: no matches after parse")
        return
      }
      if (parserResult.clubs.length === 0) {
        this.setErrorMessage("parse error: no clubs after parse")
        return
      }

      this.parserResultMatches = JSON.stringify(parserResult.matches,undefined, 2)
      this.parserResultClubs = JSON.stringify(parserResult.clubs,undefined, 2)
      if (!this.form.clubId) { 
        // nehme die automatisch ermittelte clubId
        this.form.clubId = parserResult.clubId
      }
      if (!this.form.title) { 
        this.form.title = "" + this.form.teamNr + ". Mannschaft " + (this.form.round === 'vr' ? 'Hinserie ' : 'Rückserie ') + parserResult.seasonYear
      }
    },
    insertDb_PollAndMatches() {
      if (!this.parserResultMatches) {
        throw "parserResultMatches not set"
      }

      let parserResultMatchesArray = JSON.parse(this.parserResultMatches)
      if (!parserResultMatchesArray) {
        throw "parserResultMatchesArray not set"
      }

      this.insertDb_Poll_Disabled = true
      
      var insertMatches = parserResultMatchesArray.map(function(m) {
        m.basis_id = null
        return m
      })
      let form = this.form
      let newPoll = {
          title:form.title,
          groupId:form.groupId,
          club_nr:form.clubId,
          team_nr:form.teamNr,
          active: true,
          players:[],
          matches:insertMatches,
          round:this.form.round
      }
      
      var self = this
      
      DBAPI.putDBInsertPoll(newPoll).then(function(response) {
          // console.log("response.data", response.data.playerSchedules);
          if (response.data.error || response.data.errors) {
            //app.errorMessage = response.data.message;
            console.error("error DBAPI.putDBInsertPoll:")
            console.error(response)
            self.setErrorMessage(response.data)//.message
          }	else {
            // TODO: zeige neuen Poll an mit Auswahl an Spielern, die aufgenommen werden können
            console.log("insert poll ok", response.data)
          }

      }).catch(e => {
              console.log("putDBInsertPoll catch: ", e)
              })
        .finally(() => self.insertDb_Poll_Disabled = false)
    
  },
  insertDb_Clubs() {
      if (!this.parserResultClubs) {
        throw "parserResultClubs not set"
      }

      let parserResultClubsArray = JSON.parse(this.parserResultClubs)
      if (!parserResultClubsArray) {
        throw "parserResultClubsArray not set"
      }

      this.insertDbClubsDisabled = true
  
      // var updateClubs = parserResultClubsArray

      // var dataArr = {origin: "ImportMatches",
      //       dbactions: [{dbname: "teams", action: "update", keys:["id"], inputData: updateClubs}]
      //   }
      // var data = JSON.stringify(dataArr)
      var data = parserResultClubsArray
      var self = this

      DBAPI.putUpsert({model:'Team', keyNames:['club_nr'], items:data}).then(function(response) {
          // console.log('response', response)
          if (response.data.error || response.data.errors ){
            
            console.error("error DBAPI.putUpsert:")
            console.error(response.data)
            self.setErrorMessage(response.data)//.message
          }	else {
            console.log("insert clubs ok", response.data)
          }

      }).catch(e => {
              console.log("putUpsert catch: ", e)
              })
        .finally(() => self.insertDbClubsDisabled = false)
      }
    
  }
}

</script>

<style>

</style>
